import React , { Component } from "react";

class HistoryVersion extends Component{
    render(){
        return(
            <div id="history" className={`download-area ${this.props.horizontal}`}>
                <div className="bg-overlay"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 pt--50">  
                                <h2 className="text-white">ИСТОРИЯ ИЗМЕНЕНИЯ <span className="theme-color">ВЕРСИЙ</span></h2>
                                <img src={require('../assets/images/app/title-icon-4.webp')} alt="Cosmos-diagram Logo"/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.14.0</p>
                                <p><a href="https://www.youtube.com/watch?v=628YoN33S6o" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/b9bf2cd0d7924a4b6e97b930aae8b06c/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239033" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.14.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.13.0</p>
                                <p><a href="https://www.youtube.com/watch?v=gI-CmBQluVI" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/2329c802a0c85d88572e2626d6c98373/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239032" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.13.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.12.0</p>
                                <p><a href="https://www.youtube.com/watch?v=soqlk6lxmqw" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/6ef55f57c0be52f48eb4a1f413a1e74d/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239030" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.12.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.11.0</p>
                                <p><a href="https://www.youtube.com/watch?v=Tz7Pw1vxVtE" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/32b5253c49f526d4513a307878a1994d/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239029" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.11.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.10.0</p>
                                <p><a href="https://www.youtube.com/watch?v=4BG9ByH688U" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/6bfeccb903f60dabcbe6c71bbc750cea/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239026" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.10.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.9.0</p>
                                <p><a href="https://www.youtube.com/watch?v=HK1Cqobw58w" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/d82a9c7a3e89097d7f378df071f8ac6c/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239025" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.9.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.1.8.0</p>
                                <p><a href="https://www.youtube.com/watch?v=Xw4DP-9wJrk" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/2d8f8f701d2aaec2a28e643c837f3b00/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239024" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.1.8.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.6.0</p>
                                <p><a href="https://www.youtube.com/watch?v=I4NMAXt-Rwk" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/a208b4702a12370ec3042239a6f4d4b4/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239023" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.0.6.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.5.0</p>
                                <p><a href="https://www.youtube.com/watch?v=rhIweCtDb3s" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/f7d10b65e33a8d04089340497abceceb/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239022" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.0.5.0-setup.zip" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.4.0</p>
                                <p><a href="https://www.youtube.com/watch?v=IJJXErFR3_0" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/7a0c21b252244f13cb0dc5e6d1093406/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239021" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/cosmos-diagram-v1.0.4.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.3.0</p>
                                <p><a href="https://www.youtube.com/watch?v=Rt5tIIu7Gl4" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/b6f4ec537210e8b975f9e92962ae0bfa/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239020" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.3.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.2.0</p>
                                <p><a href="https://www.youtube.com/watch?v=M65gUuAKc64" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/4723de2cb1161d5afba53228267760b2/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239019" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.2.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.1.0</p>
                                <p><a href="https://www.youtube.com/watch?v=KJcj6x8l0Rs" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на YOUTUBE</a></p>
                                <p><a href="https://rutube.ru/video/1ba56fa8069b9ac0cbc41aa3811231cd/" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239018" target="_blank" className={"underline theme-color"}>Что нового? Видеообучение на VK Video</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.1.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="download-buttons pb--50">
                                <p className="text-white">Version 1.0.0.0</p>
                                <p><a href="https://www.youtube.com/watch?v=IzVJMCyL7hQ" target="_blank" className={"underline theme-color"}>Основное Видеообучение на YOUTUBE</a></p>                                
                                <p><a href="https://rutube.ru/video/83d80448cb7988bc9eab150b83387851/" target="_blank" className={"underline theme-color"}>Основное Видеообучение на RUTUBE</a></p>
                                <p><a href="https://vk.com/video-226297239_456239017" target="_blank" className={"underline theme-color"}>Основное Видеообучение на VK Video</a></p>
                                <a href="/downloads/canvas-diagram-v1.0.0.0-setup.exe" target="_blank" className="download-btn">
                                    <i className="zmdi zmdi-windows"></i>
                                    <span>Скачать для <span className="large-text">Windows</span> </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HistoryVersion;







